import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/components/Home'
import Results from '@/components/Results'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/kkk',
    name: 'kkk',
    component: Home
  },
  {
    path: '/results',
    name: 'results',
    component: Results
  }
];


export default new Router({
  // mode: 'history',
  routes: routes
})


// import { createWebHistory, createRouter } from "vue-router";
// // import Home from "@/views/Home.vue";
// // import About from "@/views/About.vue";
// // import Home from "@/components/Home.vue";
// // import About from "@/components/Results.vue";
// import Home from '@/components/Home.vue';
// import Results from '@/components/Results.vue';
// import HelloWorld from '@/components/HelloWorld.vue';

// const routes = [
//   // {
//   //   path: "/",
//   //   name: "Home",
//   //   component: Home,
//   // },
//   // {
//   //   path: "/about",
//   //   name: "About",
//   //   component: About,
// 	// },
// 	{
// 		path: '/hello',
// 		name: 'hello',
// 		component: HelloWorld
// 	},
// 	{
// 		path: '/',
// 		name: 'home',
// 		component: Home
// 	},
// 	{
// 		path: '/kkk',
// 		name: 'kkk',
// 		component: Home
// 	},
// 	{
// 		path: '/results',
// 		name: 'results',
// 		component: Results
// 	}
// ];




// // import Vue from 'vue'
// // import Router from 'vue-router'

// // import Home from '@/components/Home'
// // import Results from '@/components/Results'

// // Vue.use(Router)

// // export default new Router({
// //   mode: 'history',
// //   routes: [
// //     {
// //       path: '/',
// //       name: 'home',
// //       component: Home
// //     },
// //     {
// //       path: '/kkk',
// //       name: 'kkk',
// //       component: Home
// //     },
// //     {
// //       path: '/results',
// //       name: 'results',
// //       component: Results
// //     }
// //   ]
// // })



















// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// });

// export default router;



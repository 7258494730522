<template>

  <div id="app">

    <nav class="level">
      <div class="level-left">
        <!-- <img alt="Eurheka logo" class="eurheka-logo" src="./assets/favicon.png"> -->
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           width="140.306px" height="59.227px" viewBox="0 0 140.306 59.227" enable-background="new 0 0 140.306 59.227"
           xml:space="preserve" class="eurheka-logo"
           v-bind:class="{ 'done': !isWakingUpServer }">
          <g>
            <path d="M123.967,44.896V13.683H20.117L18.687,0v13.683H0l18.687,1.225V46.02l103.646-0.042l1.634,13.249V46.02h16.338
              L123.967,44.896z M56.021,42.531c-22.491-1.73-23.439-2.323-28.719-3.439c-5.655-1.195-6.695-24.132-6.695-24.132l43.073,2.349
              c35.842,1.889,47.33,4.799,53.406,6.944c4.139,1.461,4.993,20.507,4.993,20.507S78.588,44.267,56.021,42.531z"/>
          </g>
        </svg>
      </div>

      <div class="level-right">
        <p class="level-item">
          <router-link class="navbar-item nav-link" exact-active-class="button active" to="/">
            Home
          </router-link>
        </p>
        <p class="level-item">
          <router-link class="navbar-item nav-link" exact-active-class="button active" to="/results">
            Resultados
          </router-link>
        </p>
      </div>
    </nav>

    <div class="container main">
      <router-view></router-view>
    </div>

    <div class="footer level">
      <div class="level-left">
        <!-- <i id="loadingIcon" class="fas fa-spinner loading waiting"
          v-bind:class="{ 'fa-spin': isWakingUpServer === true }"
          v-if="isWakingUpServer"
        ></i>
        <i id="loadingIcon" class="fas fa-check-circle loading done"
          v-if="!isWakingUpServer"
        ></i> -->
      </div>
      <div class="level-right">
        <div>
          <div>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="140.306px" height="59.227px" viewBox="0 0 140.306 59.227" enable-background="new 0 0 140.306 59.227"
               xml:space="preserve" class="eurheka-logo"
               v-bind:class="{ 'done': !isWakingUpServer }">
              <g>
                <path d="M123.967,44.896V13.683H20.117L18.687,0v13.683H0l18.687,1.225V46.02l103.646-0.042l1.634,13.249V46.02h16.338
                  L123.967,44.896z M56.021,42.531c-22.491-1.73-23.439-2.323-28.719-3.439c-5.655-1.195-6.695-24.132-6.695-24.132l43.073,2.349
                  c35.842,1.889,47.33,4.799,53.406,6.944c4.139,1.461,4.993,20.507,4.993,20.507S78.588,44.267,56.021,42.531z"/>
              </g>
            </svg>
          </div>
          <div>
            <strong>
              <i id="loadingIcon" class="far fa-copyright"
                v-bind:class="{ 'fa-spin': isWakingUpServer === true }"
              ></i>

                <!-- v-if="isWakingUpServer" -->
              <!-- <i id="loadingIcon" class="fas fa-check-circle loading done"
                v-if="!isWakingUpServer"
              ></i> -->
              EURHEKA SUMINISTROS S.L. 2020
              <div class="version">20210714</div>
            </strong>
          </div>
          <!-- <div>Powered by Vue + Node</div> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: "app",
  data: () => ({
    isWakingUpServer: true,
    errors: []
  }),
  created() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/up`)
      .then(response => {
        console.log("response:")
        console.log(response)
        this.isWakingUpServer = false
      })
      .catch(e => this.errors.push(e))
  }
};

</script>

<style scoped>

@import 'https://use.fontawesome.com/releases/v5.0.6/css/all.css';
@import 'https://cdn.materialdesignicons.com/2.0.46/css/materialdesignicons.min.css';

</style>

<style lang="scss" scoped>

</style>

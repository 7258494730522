// import Vue from 'vue'
// import App from './App.vue'

// Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')


// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'



require('./app.scss')
// require('./assets/testing.css')

Vue.config.productionTip = false

Vue.use(Buefy)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)



import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons'

library.add(faUserSecret)
library.add(faCopy)
library.add(faCommentSlash)

Vue.component('fa', FontAwesomeIcon)

// Vue.config.productionTip = false


new Vue({
  el: '#app',
  router,
  render: h => h(App)
})






/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>'
// })

<template>
	<div class="home">

		<div class="results column is-10 is-offset-1">

			<file-pond
        allow-multiple="true"
        max-files="1"
        :files="file"
        name="file"
        acceptedFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @processfilerevert="processfilerevert"
        @processfile="processfile"
        @abortProcessing="abortProcessing"
        labelIdle='Arrastra aquí el archivo de Articulos del Proveedor o <span class="filepond--label-action"> Explora archivos </span>'
        dropOnPage="true"
        dropOnElement="false"
        />

		</div>

		<!-- <div class="results column is-10 is-offset-1" v-if="columnsError"> -->
		<div class="results column is-10 is-offset-1" v-if="result.function == 'checkHeaders'">
			<div class="ko">
				<p class="title">Revisar Columnas</p>
				<p class="heading">No se han encontrado las columnas que se esperaban</p>
			</div>
			<div class="columns" style="margin-top: 2rem">
				<div class="column"
					v-bind:class="{ 'is-6': missing.length + remain.length != 0, 'is-12': missing.length + remain.length === 0 }">

					<div style="background: #f0f0f0;border-radius: 1rem; padding: 1rem;">
						<p>Asegúrate que las columnas tiene el orden correcto.</p>
						<p>Se esperaban las siguientes columnas en el archivo en el siguiente orden:</p>
						<ul class="excel-columns has-text-left">
							<li v-for="(column, index) in result.expectedColumns" v-bind:key="column.id">
								<span>COL {{ index }}</span><strong>{{column}}</strong>
							</li>
						</ul>
					</div>
				</div>
				<div class="column is-6 ko" v-if="missing.length || remain.length">
					<div style="background: #fcebeb; border-radius: 1rem; padding: 1rem;">
						No se han encontrado las siguientes columnas en el archivo:
						<ul>
							<li v-for="column in missing" v-bind:key="column.id">
								<strong>{{column}}</strong>
							</li>
						</ul>
					</div>
					<div class="u-m" v-bind:class="{ 'm-t': missing.length != 0 }" v-if="remain.length" style="background: #fcebeb; border-radius: 1rem; padding: 1rem;">
						Las siguientes columnas en el archivo no son correctas:
						<ul>
							<li v-for="column in remain" v-bind:key="column.id">
								<strong>{{column}}</strong>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>


		<div class="results column is-10 is-offset-1" v-if="result.function == 'pricesValidation'">
			<div class="ko">
				<p class="title">Revisar Valores de Filas</p>
				<p class="heading">Los valores de los precios no son correctos</p>
			</div>
			<div class="columns" style="margin-top: 2rem">
				<div class="column is-12 errorBox">

					<ul class="excel-columns has-text-left">
						<li v-for="(mistake, i) in result.mistakes" v-bind:key="mistake.id">
							{{ i }} - <strong>{{ mistake.sku }}</strong>
							<ul class="excel-columns has-text-left">
								<li v-for="(error, j) in mistake.errorsList" v-bind:key="error.id">
									<!-- Error {{ j }}: {{ error.value }}: {{ error.detail }} -->
									{{ error.value }}: {{ error.detail }}
								</li>
							</ul>
						</li>
					</ul>


				</div>
				<!-- <div class="column"
					v-bind:class="{ 'is-6': missing.length + remain.length != 0, 'is-12': missing.length + remain.length === 0 }">

					<div style="background: #f0f0f0;border-radius: 1rem; padding: 1rem;">
						<p>Asegúrate que las columnas tiene el orden correcto.</p>
						<p>Se esperaban las siguientes columnas en el archivo en el siguiente orden:</p>
						<ul class="excel-columns has-text-left">
							<li v-for="(column, index) in result.expectedColumns" v-bind:key="column.id">
								<span>COL {{ index }}</span><strong>{{column}}</strong>
							</li>
						</ul>
					</div>
				</div>
				<div class="column is-6 ko" v-if="missing.length || remain.length">
					<div style="background: #fcebeb; border-radius: 1rem; padding: 1rem;">
						No se han encontrado las siguientes columnas en el archivo:
						<ul>
							<li v-for="column in missing" v-bind:key="column.id">
								<strong>{{column}}</strong>
							</li>
						</ul>
					</div>
					<div class="u-m" v-bind:class="{ 'm-t': missing.length != 0 }" v-if="remain.length" style="background: #fcebeb; border-radius: 1rem; padding: 1rem;">
						Las siguientes columnas en el archivo no son correctas:
						<ul>
							<li v-for="column in remain" v-bind:key="column.id">
								<strong>{{column}}</strong>
							</li>
						</ul>
					</div>
				</div> -->
			</div>
		</div>


		<div class="results column is-10 is-offset-1" v-if="result.function == 'rowToArticle'">
			<div class="ko">
				<p class="title">Revisar {{ result.mistakes.length }} Filas</p>
				<p class="heading">Hay filas que no tienen la información necesaria</p>
			</div>
			<div class="columns" style="margin-top: 2rem">
				<div class="column is-12 errorBox">

					<ul class="excel-columns has-text-left">
						<li v-for="(mistake, i) in result.mistakes" v-bind:key="mistake.id">
							{{ i }} - <strong>{{ mistake.sku }}</strong>
							<ul class="excel-columns has-text-left">
								<li v-for="(error, j) in mistake.errorsList" v-bind:key="error.id">
									<!-- Error {{ j }}: {{ error.value }}: {{ error.detail }} -->
									{{ error.value }}: {{ error.detail }}
								</li>
							</ul>
						</li>
					</ul>

				</div>
			</div>
		</div>















		

		<div v-if="prices.length">
			<!-- <h1>Revisar Precios de {{prices.length}} artículos</h1> -->
			<div class="ko">
				<p class="title">Revisar Precios</p>
				<p class="heading">Auditar el margen de {{prices.length}} artículos</p>
			</div>
			<!-- <ul>
				<li v-for="price in prices">
					{{ price }}
				</li>
			</ul> -->
			<b-table
				:data="false ? [] : prices"
				>
				<!-- :striped="true"
				:hoverable="true"
				:mobile-cards="true" -->

				<!-- <template slot-scope="articles">
					<b-table-column>
						<a class="button is-small button-copy"
							v-if="articles.row.skuEurheka"
							v-clipboard:copy="articles.row.skuEurheka"
							v-clipboard:success="onCopy"
							v-clipboard:error="onError">
							<fa icon="copy" />
						</a>
					</b-table-column>
					<b-table-column label="sku Eurheka" field="difference" sortable>
						{{ articles.row.skuEurheka }}
					</b-table-column>
					<b-table-column label="Precio Coste">
						{{ articles.row.articlePrecioCoste }}
					</b-table-column>
					<b-table-column label="PVP">
						{{ articles.row.articlePvp }}
					</b-table-column>
					<b-table-column label="Margen">
						{{ articles.row.articleMargen }}
					</b-table-column>
					<b-table-column label="Margen Calculado">
						{{ articles.row.marginRounded }}
					</b-table-column>
					<b-table-column label="Desviation" field="desviation" sortable>
						{{ articles.row.desviation }}%
					</b-table-column>
				</template> -->
			</b-table>
		</div>

<!-- <pre>{{failedArticles}}</pre> -->

		<div v-if="failedArticles.length" class="results column is-10 is-offset-1">
			<div class="level-item has-text-centered">
				<div class="ko">
					<p class="heading">Failed</p>
					<p class="title">{{ failedArticles.length }} articles</p>
				</div>
			</div>

			<b-table :data="failedArticles" :columns="columns">

			<!-- </b-table> -->

			<!-- <b-table
				:data="false ? [] : failedArticles"
				:striped="true"
				> -->
				<!-- <template slot-scope="fail">
					<b-table-column>
						<a class="button is-small button-copy"
							v-if="fail.row.article.sku"
							v-clipboard:copy="fail.row.article.sku"
							v-clipboard:success="onCopy"
							v-clipboard:error="onError">
							<fa icon="copy" />
						</a>
					</b-table-column>
					<b-table-column label="Fila">
						{{ fail.row.article.row }}
					</b-table-column>
					<b-table-column field="sku" label="SKU">
						{{ fail.row.article.sku }}
					</b-table-column>
					<b-table-column class="error" field="fail" label="Error">
						<div class="errorDetail" v-for="error in fail.row.err.details" v-bind:key="error.id">
							{{ error.message }}. (waiting "{{ error.type }}")
							<br>
							<span>"{{ error.context.value }}" was read</span>.
						</div>
					</b-table-column>
				</template> -->

			</b-table>
		</div>

		<div v-if="okArticles > 0" class="level-item has-text-centered">
			<div class="ok">
				<p class="heading">OK articles</p>
				<p class="title">{{ okArticles }}</p>
			</div>
		</div>

		<!-- <pre v-if="prices" style="text-align: left">
			{{ prices }}
		</pre> -->

	</div>


</template>

<script>

import * as R from 'ramda';

import vueFilePond from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import FilePond plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Create component
const FilePond = vueFilePond(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview
);

import { setOptions } from 'vue-filepond';

setOptions({
  server: {
    url: process.env.VUE_APP_API_URL + '/validate',
    revert: null,
  }
})


export default {
	name: 'Home',
	data: function () {
		console.log(`process.env.VUE_APP_API_URL + '/validate':\n${JSON.stringify(process.env.VUE_APP_API_URL + '/validate', null, 2)}`);
		return {
			result: {},
			dropzoneOptions: {
				url: process.env.VUE_APP_API_URL + '/validate',
				thumbnailWidth: 150,
				headers: { 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8' },
				maxFilesize: 5
			},
			file: [],
			okArticles: 0,
			failedArticles: [],
			columnsError: false,
			columnsRequired: [],
			columnsGet: [],
			// columnsRequired: ["SKU", "SKU EURHEKA", "SKU PROVEEDOR", "NOMBRE", "FAMILIA", "PROVEEDOR", "MARCA", "DESCRIPCION CORTA", "DESCRIPCION LARGA", "PRECIO COSTE", "PRECIO UNIDAD", "PVP", "UNIDAD PAQUETE", "UNIDAD MEDIDA", "DTO MAXIMO", "MARGEN", "STOCK MINIMO", "CANTIDAD POR DEFECTO", "CODIGO BARRAS PROVEED"],
			// columnsGet: ["SKU", "SKU EURHEKA", "SKU PROVEEDOR", "NOMBRE", "FAMILIA", "PROVEEDOR", "MARCA", "blabla", "DESCRIPCION LARGA", "PRECIO COSTE", "PRECIO UNIDAD", "PVP", "UNIDAD PAQUETE", "UNIDAD MEDIDA", "DTO MAXIMO", "MARGEN", "STOCK MINIMO", "CANTIDAD POR DEFECTO", "CODIGO BARRAS PROVEED"],
			missing: [],
			remain: [],
			prices: [],
			columns: [
				{
						field: '_original.sku',
						label: 'Sku',
				},
				{
						field: '_original.skuEurheka',
						label: 'Sku Eurheka',
				},
				{
						field: '_original.skuProveedor',
						label: 'Sku Proveedor',
				},
				{
						field: '_original.nombre',
						label: 'Nombre',
				},
				{
						field: '_original.proveedor',
						label: 'Proveedor',
				},
				{
						field: '_original.precioCoste',
						label: 'Precio Coste',
				},
				{
						field: '_original.pvp',
						label: 'Pvp',
				},
				{
						field: '_original.margen',
						label: 'Margen',
				}
			]
		}
	},
	methods: {
		alert() {
			this.$buefy.dialog.alert('There was an undefined error :|')
		},
		abortProcessing: function(error, file) {
			this.result = {};
			debugger
		},
		processfile: function(error, file) {

			this.result = {};

			debugger

			if (error) {
				
				console.table(error)
				debugger

				if(error.body){
					this.alert();
				}
				
			} else {

				let response = JSON.parse(file.serverId)
				console.log(`response`)
				console.log(response)
				// debugger

				if(response.result === false){

					// console.log(`response.error:\n${JSON.stringify(response.error, null, 2)}`);
					// console.table(response.ko)
					// debugger
					this.result = response
					// switch(response.function){
					// 	case 'checkHeaders':

					// 		break;
					// }
					console.log(`this.result: `)
					console.log(this.result)
					debugger

					switch(this.result.function){
					// 	case 'Rows Eval Validation':
					// 		this.failedArticles = response.ko
					// 		// console.table(response.ko)
					// 		// debugger
					// 		break;
					// 	case 'Prices Validation':
					// 		this.prices = response.ko
					// 		console.table(response.ko)
					// 		// debugger
					// 		break;
						case 'checkHeaders':
					// 		this.columnsError = true;
					// 		this.columnsRequired = response.ko.required
					// 		this.columnsGet = response.ko.headers
							this.missing = R.difference(this.result.expectedColumns, this.result.mistakenColumns)
							this.remain = R.difference(this.result.mistakenColumns, this.result.expectedColumns)
							debugger
							break;
					// 	case 'There Are Failed Articles':
					// 		this.failedArticles = response.ko
					// 		console.table(response.ko)
					// 		break;

					}
				} else {
					// this.alert();

					if(JSON.parse(file.serverId).okArticles)
						this.okArticles = JSON.parse(file.serverId).okArticles
					if(JSON.parse(file.serverId).failedArticles)
						this.failedArticles = JSON.parse(file.serverId).failedArticles
				}
			}
		},
		processfilerevert: function(source, load, error){
			this.file = []
			this.okArticles = 0
			this.failedArticles = []
			this.prices = []
			this.columnsError = false
		},
		onCopy: function (e) {
			this.$toast.open({
				message: `Has copiado el SKU: ${e.text}`,
				position: 'is-bottom',
			})
		},
		onError: function (e) {
			this.$toast.open({
				message: `Failed to copy texts`,
				position: 'is-bottom',
				type: 'is-danger'
			})
		}
	},
	filters: {
		pretty: function(value) {
			return JSON.stringify(JSON.parse(value), null, 2);
		}
	},
	components: {
		FilePond
	}
}

</script>

<style scoped>

li > ul {
	margin-top: 0;
	margin-bottom: 2rem;
}

</style>

<template>
	<div>

		<!-- <pre>{{ isWakingUpServer }}</pre> -->

		<b-table :data="false ? [] : results"
						 :row-class="(row, index) => !row.result && 'error'"
						 :default-sort-direction="'desc'"
						 :opened-detailed="defaultOpenedDetails"
						 detailed
						 detail-key="date"
						 icon-pack="fa"
						 default-sort="date"
						 :show-detail-icon="handleDetailed"
						 @select="selected"
						 >
						 <!-- @details-open="(row, index) => closeAllOtherDetails(row, index)" -->
				<!-- row: {{row}} -->
				
				<!-- <b-table-column field="_id" label="Id" width="10" v-slot="props">
					{{ props.row._id }}
				</b-table-column> -->
				<!-- <b-table-column field="file" label="File" v-slot="props" @click="toggle(props.row)"> -->
				<b-table-column field="file" label="File" v-slot="props">
					<img src="../assets/ico-excel.svg" alt="" class="ico-excel">
					{{ props.row.file }}
				</b-table-column>
				<b-table-column field="result" label="Result" v-slot="props">
					{{ props.row.result }}
				</b-table-column>
				<b-table-column field="okArticles" label="Ok Articles" v-slot="props">
					{{ props.row.okArticles }}
				</b-table-column>
				<b-table-column field="date" label="Date" v-slot="props">
					{{ props.row.date | moment }}
				</b-table-column>

				<template slot="detail" slot-scope="props2" v-if="props2.row.mistakes.length > 0">

<!-- props2.row.mistakes.length > 0: {{ props2.row.mistakes.length > 0 }}
<br>
clase: {{ props2.row.mistakes.length > 0 ? 'error' : 'noooooerror' }}
<h3>ERRORS</h3>
-->

					
					<b-table :data="props2.row.mistakes"
									 detailed
									 >

						<b-table-column field="sku" v-slot="errors">
							<span v-if="errors.row.sku">{{ errors.row.sku }}</span>
							<span v-if="!errors.row.sku" class="error">NO SKU</span>
							<!-- {{ errors.row.sku }}
							<span></span> -->
						</b-table-column>

						<template slot="detail" slot-scope="kkk" class="uep">
							<!-- kkk: {{ kkk }} -->

							<!-- <b-table :data="kkk.row.errorsList" :columns="columnsInside"></b-table> -->

							<b-table :data="kkk.row.errorsList" class="amem">

								<b-table-column field="value" label="Value" v-slot="props">
									{{ props.row.value }}
								</b-table-column>
								<b-table-column field="detail" label="Detail" v-slot="props">
									{{ props.row.detail }}
								</b-table-column>
							
							</b-table>

						</template>

					</b-table>

					<!-- </section> -->

				</template>





				<!-- <b-table-column field="date" label="Date" sortable>
					{{ test.row.date | formatDate }}
				</b-table-column>
				<b-table-column field="file" label="File" sortable>
					<img src="../assets/ico-excel.svg" alt="" class="ico-excel">
					{{ test.row.file }}
				</b-table-column>
				<b-table-column field="ok" label="Ok">
					{{ test.row.okArticles }}
				</b-table-column>
				<b-table-column field="failed" label="Failed">
					{{ test.row.mistakes.length }}
				</b-table-column> -->

			<!-- </template> -->
			

			<!-- <template slot="detail" slot-scope="props">
				<b-table
					:data="false ? [] : props.row.mistakes"
					:striped="true"
					>
					<template slot-scope="detail">
						<b-table-column class="error" label="Error Detail">
							<b-table-column field="sku" label="SKU">
								{{ detail.row.sku }}
							</b-table-column>
							<b-table-column field="err" label="SKU">
								<div class="errorDetail" v-for="error in detail.row.errorsList" v-bind:key="error.id">
									{{ error.detail }}. <span>"{{ error.value }}" was read</span>.
								</div>
							</b-table-column>
						</b-table-column>
					</template>
				</b-table>
			</template> -->

		</b-table>

	</div>
</template>


<script>

import './results.scss'

import axios from 'axios'
import moment from 'moment'

export default{
	name:'Results',
	mounted(){
		console.log(`mounted :: process.env.API: ${process.env.API}`)
	},
	data (){
		return{
			defaultOpenedDetails: [1],
			title:'Results',
			results: [],
			items: [
				{ age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
				{ age: 21, first_name: 'Larsen', last_name: 'Shaw' },
				{ age: 89, first_name: 'Geneva', last_name: 'Wilson' },
				{ age: 38, first_name: 'Jami', last_name: 'Carney' }
			],
			isWakingUpServer: null,
			// https://buefy.org/documentation/table

			columnsInside: [
				{
						field: '_id',
						label: 'Id',
				},
				{
						field: 'value',
						label: 'Value',
				},
				{
						field: 'detail',
						label: 'Detail',
				},
			]
		}
	},
	created() {
		axios
			.get(`${process.env.VUE_APP_API_URL}/results`)
			.then(response => {
				this.results = response.data.docs
				console.table(this.results)
			})
			.catch(e => this.errors.push(e))
	},
	filters: {
		Upper(value) {
			return value.toUpperCase();
		},
		formatDate(value) {
			if (value) {
				return moment(String(value)).format('DD/MM/YYYY HH:mm')
			}
		}
	},
	methods: {
		toggle(row) {
			console.log(`row:\n${JSON.stringify(row, null, 2)}`);
			debugger
			this.$refs.table.toggleDetails(row)
		},
		handleDetailed(row) {
			debugger
			return false
      // return user.id !== 1;
		},
		selected(item) {
			console.log(`item:\n${JSON.stringify(item, null, 2)}`);
			// this.$refs.table.toggleDetails(row)
			// this.$router.push({ path: '/details/:recordId' })
			debugger
		},
		closeAllOtherDetails(row, index) {

			console.log(`row:\n${JSON.stringify(row, null, 2)}`);
			
			console.log(`index:\n${JSON.stringify(index, null, 2)}`);
			
			debugger

			this.defaultOpenedDetails = [row.id]

		}
	},
	filters: {
		moment: function (date) {
			return moment(date).format('DD/MM/YYYY HH:mm:ss');
		}
	}
}

</script>
